<template>
  <div class="w-full home">
    <div class="mb-8 hero"></div>
    <h1 class="text-2xl uppercase">
      Peace of
      <span>Mind Cleaning &amp; Concierge</span> Service
    </h1>
    <h2 class="mb-4 text-lg">“We are passionate about our work”</h2>
    <div
      id="services"
      class="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4"
    >
      <div class="mb-3 border rounded-lg shadow-lg border-gray card">
        <i class="p-6 text-5xl fas fa-home" />
        <h3 class="text-xl text-gray-600 uppercase font-b">
          Residential Cleaning
        </h3>
        <div class="px-6 py-4 text-left">
          At Peace of Mind Cleaning &amp; Concierge Service, we customize each
          cleaning to meet our customers needs. Whether you are looking for a
          detailed cleaning or a quick freshening up, we can handle all of your
          household cleaning needs.
        </div>
      </div>

      <div class="mb-3 border rounded-lg shadow-lg border-gray card">
        <i class="p-6 text-5xl fas fa-concierge-bell" />
        <h3 class="text-xl text-gray-600 uppercase font-b">
          CONCIERGE SERVICE
        </h3>
        <div class="px-6 py-4 text-left">
          Peace of Mind Cleaning &amp; Concierge Service provides a trained
          personal assistant who will help with your daily household tasks. We
          provide a variety of different services: in- home laundry, personal
          shopping, property management &amp; relocation specialist assistance,
          walking pets, etc. Pick and choose what is needed for each visit and
          let a trained professional take care of it.
        </div>
      </div>

      <div class="mb-3 border rounded-lg shadow-lg border-gray card">
        <i class="p-6 text-5xl fas fa-truck-moving" />
        <h3 class="text-xl text-gray-600 uppercase font-b">Move Cleaning</h3>
        <div class="px-6 py-4 text-left">
          At Peace of Mind Cleaning &amp; Concierge Service we offer high
          quality move-in and move-out cleanings. We have experience with both
          large houses as well as small apartments and we can accommodate to any
          size project.
        </div>
      </div>

      <div class="mb-3 border rounded-lg shadow-lg border-gray card">
        <i class="p-6 text-5xl fas fa-building" />
        <h3 class="text-xl text-gray-600 uppercase font-b">
          JANITORIAL SERVICES
        </h3>
        <div class="px-6 py-4 text-left">
          Peace of Mind Cleaning &amp; Concierge Service has had the privilege
          to work with some of the best businesses and property management
          companies in the Milwaukee area. We can accommodate any size business
          and will customize our service to fit your needs and schedule.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, inject } from "vue";
import { logout, getUserName } from "../firebase.js";

export default {
  inject: ["global"],
  setup() {
    const name = ref("");
    onBeforeMount(() => {
      name.value = getUserName();
    });
    return {
      name,
      logout,
      getUserName,
    };
  },
};
</script>

<style lang="scss" scoped>
.bullet-list {
  text-align: left;

  li {
    margin: 0 0 8px 0;
  }

  li:before {
    content: "✓ ";
  }
}
.hero {
  background-image: url(~@/assets/images/hero-img.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 100%;
}
.home h1 {
  color: #265aa9;
}
@media (max-width: 1024px) {
  .hero {
    background-position: -200px 0px;
  }
}
@media (max-width: 500px) {
  .hero {
    height: 300px;
    background-position: -200px 0px;
  }
}
</style>